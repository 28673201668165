import {CustomPage} from '../../../models/page-builder/custom-page.model';

export function trimOutDuplicates(pages: CustomPage[]): CustomPage[] {
  const uniquePagesMap = new Map<string, CustomPage>();

  for (const page of pages) {
    uniquePagesMap.set(page.id, page);
  }

  return Array.from(uniquePagesMap.values());
}
